import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Checkout from './Checkout'
import ErrorPage from './ErrorPage'
import {Helmet} from "react-helmet";


export default function Pay() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <ErrorPage/>,
      errorElement: <ErrorPage/>
    },
    {
      path: 'pay/full-prompt-engineering-course',
      element: <Checkout/>,
      errorElement: <ErrorPage/>
    },
  ])

  return (
    <div>
      <Helmet>
        <title>CRDZandCO | Checkout | Pay with Cryptocurrencies</title>
        <link rel="icon" href="favicon.ico" />
        <link rel="apple-touch-icon" sizes="72x72" href="https://crdzandco.com/img/favicon.ico" />
        <link rel="icon" href="https://crdzandco.com/img/favicon.ico" type="image/x-icon"/>
        <link rel="apple-touch-icon" href="https://crdzandco.com/img/favicon.ico" type="image/x-icon"/>
      </Helmet>
        <RouterProvider router={router}></RouterProvider>
    </div>
  )
}