import React, { useState, useCallback } from 'react'
import { FrontComponent } from './Front'
import { FrontPayload, TransferFinishedPayload } from '@front-finance/link'

import './PayButton.css'

export const PayButton: React.FC<{}> = () => {

  const [iframeLink, setIframeLink] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [payload, setPayload] = useState<FrontPayload | null>(null);
  const [opacity, setOpacity] = useState<number>(0)
  const [display, setDisplay] = useState<boolean>(true)
  const [trasnferFinishedData, setTrasnferFinishedData] =
    useState<TransferFinishedPayload | null>(null)


  const getAuthLink = useCallback(async () => {
    setError(null)
    setIframeLink(null)
    
    const response = await fetch("https://checkout.crdzandco.com/api", {
      method: 'POST',
      headers: {
        accept: 'application/*+json',
        'content-type': 'application/*+json',
      },
      body: '{payWith: USDC}'
    })
    
    const data = await response?.json()
    
    if (response.status !== 200 || !data?.content) {
      const error = (data && data.message) || response.statusText
      console.error('Error!', error)
      setError(error)
    } else if (!data.content.linkToken) {
      setError('Iframe url is empty')
    } else {
      setIframeLink(data.content.linkToken)
    }
    
  }, [])

  return (
    <div>
      <button className={`submit-button ${display ? 'display-block' : 'display-none'}`}onClick={getAuthLink} >
        <div className='submit-button-text-container'>
          <span className='pay-with' style={{opacity:1-opacity}}>
            Pay with USDC
          </span>
          <span className='processing' style={{opacity:opacity}}>
            Processing...
          </span>
        </div>
      </button>
      <FrontComponent
        iframeLink={iframeLink}
        onExit={err => {
          setIframeLink(null)
          setError(err || null)
        }}
        onBrokerConnected={(authData: FrontPayload) => {
          setPayload(authData)
          setIframeLink(null)
        }}
        onTransferFinished={data => {
          setTrasnferFinishedData(data)
        }}
      />
    </div>
    )
}

export default PayButton;