import React, { useEffect, useState } from 'react'
import {
  FrontConnection,
  FrontPayload,
  createFrontConnection,
  TransferFinishedPayload
} from '@front-finance/link'

import {clientId} from '../utility/config'

export const FrontComponent: React.FC<{
  iframeLink?: string | null
  onBrokerConnected: (authData: FrontPayload) => void
  onTransferFinished?: (payload: TransferFinishedPayload) => void
  onExit?: (error?: string) => void
}> = ({ iframeLink, onBrokerConnected, onTransferFinished, onExit }) => {

  const [frontConnection, setFrontConnection] = useState<FrontConnection | null>(null)

  useEffect(() => {
    setFrontConnection(
      createFrontConnection({
        clientId: clientId,
        onBrokerConnected: authData => {
          onBrokerConnected(authData)
        },
        onExit: (error?: string) => {
          if (error) {
          }

          onExit?.()
        },
        onTransferFinished: transferData => {
          transferSuccess()
          onTransferFinished?.(transferData)
        }
      })
    )
  }, [])

  var currentOptions;

  var notificationId = 'notification'
  
  const createNotification = () => {
    var section = document.createElement('section')
    section.id = notificationId;
    section.classList.add('notifications')
    section.classList.add('notifications-animations')
    section.innerText = '🔗 We are checking your transaction on chain. Please give us a few seconds to validate.'
    document.querySelector('.checkout-footer')?.prepend(section)
  }

  const transferSuccess = () => {
    createNotification()

    var notification = document.getElementById(notificationId);
    if(notification){
      notification.classList.remove('notifications-animations');
      notification.innerText = '✅ Everything is ok with the transfer you will be redirected soon!';
    }
    var timeout = setTimeout(()=>{
      window.location.replace("https://crdzandco.com/aope-thank-you/")
    }, 4000)
  }
  
  useEffect(() => {
    if (iframeLink) {
      // createIframe(iframeLink)
      frontConnection?.openLink(iframeLink)
    }
  }, [frontConnection, iframeLink])

  return <></>
}
