import { Helmet } from 'react-helmet'
import Pay from './ui/Pay'

export const App = () => {
  return (
    <>
      <Helmet>
          <title>CRDZandCO | Checkout | Pay with Cryptocurrencies</title>
          <link rel="icon" href="favicon.ico" />
          <link rel="apple-touch-icon" sizes="72x72" href="https://crdzandco.com/img/favicon.ico" />
          <link rel="icon" href="https://crdzandco.com/img/favicon.ico" type="image/x-icon"/>
          <link rel="apple-touch-icon" href="https://crdzandco.com/img/favicon.ico" type="image/x-icon"/>
      </Helmet>
      <Pay/>
    </>
  )
}
