import React, { Component, useEffect, useState } from 'react'
import './Checkout.css';
import PayButton from './PayButton';

export const Checkout: React.FC = () => {

  return (
    <div className='container'>
      <div className='multi-item'>
        <div className='product-overview'>
          <header className='header'>
            <div className="header-content">
              <div className="header-business">
                <div className="header-merchant-logo">
                  <div className="header-image">
                    <img src="https://d1wqzb5bdbcre6.cloudfront.net/c611dbefccca8781630d8ebd99838c8ba965057a28648d2519147d3bac4450aa/68747470733a2f2f66696c65732e7374726970652e636f6d2f66696c65732f4d44423859574e6a6446387854545645536c6c4c56556847637a59776430463566475a6662476c325a5639685a6d7072554668775648566d55303531616c5a4b656d78464e465579636e6b303030325957626d6c4b" alt="" className="header-image-icon" />
                  </div>
                  <h1 className="header-business-name">CRDZandCO</h1>
                </div>
              </div>
            </div>
          </header>
          <div className="order-summary-column">
            <div className="product-summary">
              <div className="product-summary-info">
                <span className='product-summary-name'>Pay to CRDZandCO</span>
                <div className="product-summary-amounts-container">
                  <div className="product-summary-total">
                    <span className='product-summary-total-amount-container'>
                      <span className='product-summary-total-amount'>USD 9.00 </span>
                    </span>
                  </div>
                  {/* Here is the additional part for the responsive */}
                </div>
              </div>
            </div>
            <div className="order-details">
              <ul className="order-details-items">
                <li>
                  <div>
                    <div className='line-item-image-container'>
                      <img className="line-item-image" src="https://d1wqzb5bdbcre6.cloudfront.net/f1c1737a2154d76473edc2d2abe0f78e2833c11345ec153fe67192c21b22b1f0/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a6446387854545645536c6c4c56556847637a59776430463566475a7358327870646d5666626b526e4e476477565539686332354b537a497a6458466e556b5a795156524b30304b4f584d67365835"></img>
                    </div>
                    <div className='line-item-product-container'>
                      {/* <div className="flex-column-break"></div>
                      <div className="flex-column-break"></div> */}
                      <div className="line-item-product-name">
                        <span>
                          Full Prompt Engineer Kickstarting – BONUS: Biweekly sessions
                        </span>
                      </div>
                      <div className='line-item-description-container'>
                        <div className='line-item-description'>
                          <span>
                            <span>
                            Get access to the course fundamentals, prompt crafting techniques and documentation workflow. ADDITIONALLY: You'll get curated prompt list, prompt documentation template, content creation workflow and content structure templates.
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="line-item-total-amount">
                        <span>
                          <span>USD 9.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="order-details-footer">
                <div className="order-details-footer-subtotal">
                  <span>
                    Subtotal &nbsp;
                  </span>
                  <span className='order-details-subtotal-amount'>
                    USD 9.00
                  </span>
                </div>
                {/* PROMOTIONAL CODE NOT AVAILABLE */}
                {/* <div className="order-details-footer-sobtotal-items">
                  <div className="promotion-code-entry">
                    
                  </div>
                </div> */}
                <div className="order-details-total">
                  <span>
                    Total payment
                  </span>
                  <span className='order-details-total-amount'>
                    USD 9.00
                  </span>
                </div>
                <br />
                <div><i>🔑 After a succesful payment, you will be redirected to a series of instructions that you must follow to complete your registration.</i></div>
              </div>
            </div>
          </div>
        </div>
        <div className='product-payment'>
          <div className='checkout-payment'>
            <div className="payment-request-header">
              <div className='payment-header-container'>
                <div className='payment-header'>
                  <div>
                    Pay with Crypto
                  </div>
                </div>
              </div>
            </div>
            <div className={`process-description`}>
              <div className='yaatt'>
                You are about to transfer:
              </div>
              <div className='preview-amount'>
                9.00 USDC ~ $ 9.00 USD
              </div>
              <div className='wauff'>
                <div>⚡️</div>
                We are using <span>Mesh Connect</span> for the wallet connection so you just have to use your better wallet!
              </div>
              <div className='tp'>
                <div>⏳</div>
                The process usually takes a few minutes.  So we are using <span>Polygon</span> for fast transactions!
              </div>
              <div className='advice'>
                <div>🔐</div>
                We recommend you to  <span>disconnect</span> your wallet after the transfer!
              </div>
              
            </div>

          </div>
        <PayButton></PayButton>
        {/* <App></App> */}
        </div>
        <div className='checkout-footer'> 
          <a href="https://wa.me/message/M3CVEVIKQ3VWN1">Do you need some help? 💬</a>
        </div>
      </div>
    </div>
  )
}

export default Checkout;
